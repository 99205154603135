<template>
  <div class="team-mode">
    <EmployeeIdle v-if="isIdleAvailable" @done="onIdleDone" />
    <EmployeePinDialog v-if="dialog" @ok="onPinOk" @cancel="onPinCancel" />
    <EmployeeTimeSheetDialog v-if="isTimeSheetAvailable" @clockin="onClockIn" />
    <EmployeeWelcomeDialog
      v-model="showWelcomeDialog"
      :shifts="shifts"
      :todos="todos"
      :task-lists="taskLists"
      @close="onWelcomeClose"
    />
  </div>
</template>

<script>
import { EMPLOYEE_ACCESS_STATUS } from "@/store/modules/teamAuth";
import EmployeePinDialog from "@/components/employees/EmployeePinDialog.vue";
import EmployeeWelcomeDialog from "@/components/employees/EmployeeWelcomeDialog.vue";
import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";
import { ALL_TASK_LISTS, ACTIVE_TASK_LISTS } from "@/graphql/task/queries.gql";
import { hasRouteDay } from "@/mixins/routerParams";
import Vue from "vue";
import { PosProxy } from "@/utils/pos-proxy";
import createBarcodeScanner, { teardownBarcodeScanner } from "@/utils/barcode";

export default {
  name: "TeamMode",
  inject: ["currentStore"],
  components: {
    EmployeePinDialog,
    EmployeeWelcomeDialog,
    EmployeeTimeSheetDialog: () =>
      import(
        /* webpackChunkName: "team-mode-timesheet" */ "@/components/employees/EmployeeTimeSheetDialog.vue"
      ),
    EmployeeIdle: () =>
      import(
        /* webpackChunkName: "team-mode-idle" */ "@/components/employees/EmployeeIdle.vue"
      ),
  },
  mixins: [hasRouteDay],
  data: () => ({
    showWelcomeDialog: false,
  }),
  apollo: {
    shifts: {
      query: SHIFTS_TODAY,
      variables() {
        return this.variables;
      },
      skip() {
        return !this.currentStore()?.id;
      },
    },
    todos: {
      query: ACTIVE_TASK_LISTS,
      variables() {
        return this.variables;
      },
      skip() {
        return !this.currentStore()?.id;
      },
    },
    taskLists: {
      query: ALL_TASK_LISTS,
      variables() {
        return this.variables;
      },
      skip() {
        return !this.currentStore()?.id;
      },
    },
  },
  computed: {
    dialog() {
      return (
        this.$store.getters["auth/employeeAccessStatus"] ===
        EMPLOYEE_ACCESS_STATUS.requiredEmployeeAccess
      );
    },
    isIdleAvailable() {
      return (
        this.$store.getters["auth/employeeAccessStatus"] ===
        EMPLOYEE_ACCESS_STATUS.hasEmployee
      );
    },
    isTimeSheetAvailable() {
      return (
        this.$raiPos &&
        this.currentStore().usesExternalScheduler &&
        this.currentStore().usesTimeclock
      );
    },
    variables() {
      return {
        storeId: this.currentStore()?.id,
        businessDate: this.day,
      };
    },
  },
  mounted() {
    this.onMounted();
  },
  beforeDestroy() {
    this.clearPosProxy();
    this.clearBarcodeScanner();
  },
  methods: {
    // we know we are in team mode in this component
    async onMounted() {
      // Make sure we are definitely not on a POS
      if (!this.raiPosAsync && !this.$raiPos) {
        // init pos proxy if we are in team mode and pos hasnt been inited
        await this.initPosProxy();
      }

      // init barcode scanner if we are in team mode and either on newest pos build or proxy
      if (this.$raiPos && this.$raiPos.raiPosVersion) {
        this.initBarcodeScanner();
      }
    },
    async onPinOk(employee) {
      await this.$store.dispatch("auth/teamModeAuth", {
        employee,
        storeId: this.currentStore().id,
      });

      if (
        (!employee.clockedIn || employee.isOnBreak) &&
        this.currentStore().usesTimeclock
      ) {
        this.$store.dispatch("teamMode/showTimeSheetDialog");
      }
    },
    onPinCancel() {
      this.$store.dispatch("auth/employeeAccessNoEmployee");
    },
    onIdleDone() {
      this.$store.dispatch("teamMode/resetWithTimeout");
      this.$store.dispatch("auth/employeeAccessNoEmployee");
    },
    onClockIn() {
      if (this.$store.state.teamMode.employeeWelcomeDialog) {
        this.showWelcomeDialog = true;
        // Show and set welcome dialog to inactive (setting to inactive for next time someone clocks in)
        this.$store.dispatch("teamMode/hideEmployeeWelcomeDialog");
      }
    },
    onWelcomeClose() {
      this.showWelcomeDialog = false;
    },
    async initPosProxy() {
      await this.waitForPusher();
      // TODO: its bad practice to set $raiPos like this
      //       during vue3 move we should fix this
      //       (move to `createApp` in main.js)
      Vue.prototype.$raiPos = new PosProxy((posInfo) => {
        this.$raiError.blocking({
          title: "Connection error",
          message: `Could not connect to ${posInfo.name}`,
          hideCancel: true,
        });
      });
    },
    clearPosProxy() {
      Vue.prototype.$raiPos = undefined;
    },
    initBarcodeScanner() {
      createBarcodeScanner();
    },
    clearBarcodeScanner() {
      teardownBarcodeScanner();
    },
    waitForPusher() {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if (this.$pusher) {
            clearInterval(interval);
            resolve(true);
          }
        }, 500);
      });
    },
  },
};
</script>
